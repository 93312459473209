
import React from "react"
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import '../pages/clients-and-projects.css'
import Grid from '@material-ui/core/Grid'
import LaRoccaBuilding from '../components/la_rocca_building';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

const TestimonialSection = (props) => (
	<Paper className="customer-page-section">
	    	<Typography variant='h4' color="primary">
	    		{props.title}
	    	</Typography>
	    	<br />
	    	<Grid container spacing={3}>
	    		<Grid item xs={12} sm={6}>
			    	<Typography variant='body1'>
			    		{ props.body } 
			    	</Typography>
			    	<br />
			    	<Divider />
			    	<Typography variant='h6' color="secondary" >
	    				Technologies:
	    			</Typography>
	    			<List>
	    				{props.list}
	    			</List>
		    	</Grid>
		    	<Grid item xs={12} sm={6}>
		    		{ props.children }
		    	</Grid>
	    	</Grid>
	</Paper>

    	)
export default TestimonialSection