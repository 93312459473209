import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import SimklarScreens from "../components/simklar_screens"
import SanteScreens from "../components/sante_screens"
import Grid from "@material-ui/core/Grid"
import LaRoccaBuilding from "../components/la_rocca_building"
import TestimonialSection from "../components/testimonial-section"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Cloud from "@material-ui/icons/Cloud"
import Email from "@material-ui/icons/Email"
import MobileFriendly from "@material-ui/icons/MobileFriendly"
import PictureAsPdf from "@material-ui/icons/PictureAsPdf"
import Code from "@material-ui/icons/Code"
import Create from "@material-ui/icons/Create"
import SyncAlt from "@material-ui/icons/SyncAlt"
import Folder from "@material-ui/icons/Folder"
import GridOn from "@material-ui/icons/GridOn"
import { Link } from "gatsby"
import ContactUsButton from "../components/contact-us-button"

const ClientsAndProjects = () => (
  <Layout>
    <SEO title="Clients and projects." />
    <div className="page-body">
      <Typography variant="h3" className="clients-page-title">
        Clients and projects
      </Typography>
      <TestimonialSection
        title="La Rocca Creative Cakes"
        list={
          <>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <Folder color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="FileMaker / FileMaker Go"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <Cloud color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Cloud Hosted"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <PictureAsPdf color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="PDF Generation"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <MobileFriendly color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Mobile"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <SyncAlt color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Google Drive Integration"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <SyncAlt color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="ERP Integration"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <GridOn color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="MS Excel Export"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
          </>
        }
        body="La Rocca Creative Cakes is one of Canada's leading food manufacturers of specialty cakes and desserts.
		    		La Rocca is located in a 40,000 sq.ft production facility in Richmond Hill with over 300 full time staff.
		    		When we first began working with La Rocca in the summer of 2016 they needed help moving some of their paper-based processes to iPads and to the web. We initially utilized a 
		    		'low-code' solution (FileMaker) to quickly start putting some solutions in the hands of the production staff.
		    		Since then we have worked with La Rocca on several projects integrating with their ERP system as well as their 
		    		suite of Google products. Almost three years later we have several solutions in place and are still working with La Rocca as they continue to grow."
      >
        <LaRoccaBuilding />
      </TestimonialSection>
      <Divider />
      <TestimonialSection
        title="SimKlar Paramedic Education Tracker"
        list={
          <>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <Code color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Ruby on Rails"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <Code color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Stimulus JS"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <PictureAsPdf color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="PDF Generation"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <MobileFriendly color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Mobile"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <Email color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Auto Email Generation"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <Cloud color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Cloud Hosted"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
          </>
        }
        body="SimKlar is a product aimed at Paramedic Education Programs. It was designed and built by KLAR Solutions and is used by several
    				Paramedic college programs. The solution uses modern web technologies to store and to deliver simulations and ratings. The platform
    				also looks after tracking student hours and their patient encounters in the field. The companion IOS App allows raters to perform ratings
    				and skill check-offs remotely."
      >
        <SimklarScreens />
      </TestimonialSection>
      <Divider />
      <TestimonialSection
        title="La Cité College"
        list={
          <>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <Code color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Ruby on Rails"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <Code color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Stimulus JS"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <PictureAsPdf color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="PDF Generation"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <MobileFriendly color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Mobile"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <GridOn color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="MS Excel Export"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
            <ListItem color="secondary" dense="true" divider="true">
              <ListItemIcon>
                <Cloud color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary="Cloud Hosted"
                color="secondary"
                className="tech-item"
              />
            </ListItem>
          </>
        }
        body="KLAR Solutions worked with Ottawa's La Cité College to help them manage a simulation-based competition that they run for highschool 
    		students interested in pursuing health related studies.	Their needs were specific and unique. They needed to generate simulations 
    		in a consistent way and have the ability to generate several roles for each simulation. They also needed to create custom rating 
    		tools for each role and have the ability to pre-assign students to roles. Finally, on the day of the event, they needed their raters 
    		to be able to rate several individuals from one layout using iPads or laptops and be able to report the results in several unique ways. 
    		There would be very little time to train the raters so the interface needed to be very simple to use.
			The most challenging aspect was that the solution needed to be ready for their next event which was only 2 months away!
			In response, KLAR built a custom web app to meet their needs. KLAR worked with the team at La Cité throughout the development 
			process to ensure the product would meet the requirements on the go live date. Using an agile, iterative approach and the 
			Ruby programming language, the software and the requirements evolved throughout the process to result in a product that was ready to go on time and on budget."
      >
        <SanteScreens />
      </TestimonialSection>

      <Typography gutter="true" variant="h6" color="primary" align="center">
        Interested in seeing more of our work?
      </Typography>
      <br />
      <div className="button-area">
        <ContactUsButton buttonText="Contact Us" />
      </div>
    </div>
    <Divider />
  </Layout>
)

export default ClientsAndProjects
